<script setup>
import { Pagination, A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

defineProps({
  slides: {
    type: Array,
    default: () => [],
  },
  clientiSlides: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
})

const slider = ref(null)
const nextButton = ref(null)
const prevButton = ref(null)

const onSwiper = (swiper) => {
  slider.value = swiper
}
</script>

<template>
  <div class="main-swiper row-40-20 max">
    <template v-if="type === 'default-type'">
      <!-- eslint-disable vue/no-v-html -->
      <div class="container row-80-20" :class="[type]">
        <h2 class="heading-2 swiper-title" v-html="title" />
        <div class="container-sections p-top-4 p-bot-4">
          <swiper
            class="slider-swiper"
            :navigation="{
              nextEl: nextButton,
              prevEl: prevButton,
            }"
            :modules="[Pagination, A11y, Navigation]"
            :slides-per-group="1"
            :slides-per-view="1.1"
            :breakpoints="{
              //  >= 749px
              749: {
                slidesPerView: 2,
              },
              //  >= 1300px
              1300: {
                slidesPerView: 3,
              },
            }"
            :space-between="15"
            :pagination="{ clickable: false }"
            :lazy="true"
            @swiper="onSwiper"
          >
            <swiper-slide v-for="(slide, index) in slides" :key="index">
              <div class="slider-swiper__slide__wrap">
                <SwiperSlideContent :content="slide" :type="type" />
              </div>
            </swiper-slide>

            <!-- Navigation -->
            <div v-if="slides?.length > 1" class="swiper-buttons m-top-2">
              <button
                ref="prevButton"
                class="swiper-button-prev"
                aria-label="Button Prev"
              ></button>
              <button
                ref="nextButton"
                class="swiper-button-next"
                aria-label="Button Next"
              ></button>
            </div>
          </swiper>
        </div>
      </div>
    </template>
    <template v-else-if="type === 'clienti-type'">
      <!-- eslint-disable vue/no-v-html -->
      <div class="container row-80" :class="[type]">
        <h2 class="heading-1 swiper-title" v-html="title" />
        <div class="container-sections p-bot-4">
          <swiper
            class="slider-swiper"
            :navigation="{
              nextEl: nextButton,
              prevEl: prevButton,
            }"
            :modules="[Pagination, A11y, Navigation]"
            :slides-per-group="1"
            :slides-per-view="1.1"
            :breakpoints="{
              //  >= 749px
              749: {
                slidesPerView: 2.2,
              },
              //  >= 1700px
              1700: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }"
            :space-between="15"
            :pagination="{ clickable: false }"
            :lazy="true"
            @swiper="onSwiper"
          >
            <swiper-slide v-for="(slide, index) in slides" :key="index">
              <div class="slider-swiper__slide__wrap">
                <SwiperSlideContent :content="slide" :type="type" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Slider',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
